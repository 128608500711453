import { getCookieValue } from "./cookie";

export function getCurrentLocale(): string {
    let locale = getCookieValue("rh_locale");
    let supported_locales = ["en", "fr", "ko", "ja", "zh"]; // supported langs for the Customer Portal
    let is_supported = supported_locales.indexOf(locale) >= 0;
    let left_part = locale.split("_")[0];
    let left_supported = supported_locales.indexOf(left_part) >= 0;

    if (is_supported) {
      locale = locale;
    } else if (left_supported) {
      locale = left_part;
    } else {
      locale = "en"
    }

    return locale;
}