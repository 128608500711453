import Keycloak from "../keycloak";
import * as store from "../localstorage";
import * as cookie from "../cookie";

/**
 * Manage user sessions from client-side Customer Portal applications.
 *
 * @module session
 * @author Michael Clayton <mclayton@redhat.com>
 * @copyright Red Hat 2016
 */

var CLIENT_ID = "customer-portal";
var SSO_URL = ssoUrl();
var INTERNAL_ROLE = "redhat:employees";
var COOKIE_NAME = "rh_jwt";
var TOKEN_NAME = CLIENT_ID + "_jwt";
var REFRESH_TOKEN_NAME = CLIENT_ID + "_refresh_token";
var TIMESKEW_CACHE_NAME = CLIENT_ID + "_jwt_timeskew";
var MAX_CACHED_TIMESKEW = 60; // any timeskews with magnitude greater than this will not be cached
var REFRESH_INTERVAL = 1 * 58 * 1000; // ms. check token for upcoming expiration every this many milliseconds
var REFRESH_TTE = 90; // seconds. refresh only token if it would expire this many seconds from now
var SCRIBE_PATH = "/services/primer/session/scribe/?redirectTo=";
var FAIL_COUNT_NAME = "session_refresh_fail_count";
var FAIL_COUNT_THRESHOLD = 5; // how many times in a row token refresh can fail before we give up trying
var SCOPES = "roles Legacy_IDP_OpenID api.graphql";

var KEYCLOAK_OPTIONS = {
  realm: "redhat-external",
  clientId: CLIENT_ID,
  url: SSO_URL,
};

var KEYCLOAK_INIT_OPTIONS = {
  responseMode: "fragment",
  flow: "standard",
};

var origin; // assigned during init()
var originWithPort; // assigned during init()
var token; // assigned during init()
var refreshToken; // assigned during init()
var cachedTimeSkew; // assigned during init

var state = {
  initialized: false,
};

var events = {
  init: [],
};

/**
 * Log session-related messages to the console, in pre-prod environments.
 */
function log() {
  try {
    if (store.get("session_log") === true) {
      console.log.apply(console, arguments);
    }
  } catch (e) {}
}

/**
 * Kicks off all the session-related things.
 *
 * @memberof module:session
 */
function init(settings) {
  log("[session.js] initializing");

  // run any migrations
  migrate();

  origin = location.hostname;
  originWithPort =
    location.hostname + (location.port ? ":" + location.port : "");

  token = store.get(TOKEN_NAME) || cookie.getCookieValue(COOKIE_NAME);
  refreshToken = store.get(REFRESH_TOKEN_NAME);

  // under certain conditions, update the keycloak constructor and init options

  clearExtremeTimeSkewCache();

  cachedTimeSkew = getCachedTimeSkew();
  if (typeof cachedTimeSkew === "number") {
    KEYCLOAK_OPTIONS.timeSkew = cachedTimeSkew;
    KEYCLOAK_INIT_OPTIONS.timeSkew = cachedTimeSkew;
  }
  if (token && token !== "undefined") {
    KEYCLOAK_INIT_OPTIONS.token = token;
  }
  if (refreshToken) {
    KEYCLOAK_INIT_OPTIONS.refreshToken = refreshToken;
  }

  // expire any cookies created by session.js before the cookie had the SameSite attribute
  document.cookie =
    COOKIE_NAME +
    "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=.redhat.com; path=/";

  // initialize keycloak.js

  state.keycloak = Keycloak(KEYCLOAK_OPTIONS);

  // wire up our handlers to keycloak's events
  state.keycloak.onAuthSuccess = onAuthSuccess;
  state.keycloak.onAuthError = onAuthError;
  state.keycloak.onAuthRefreshSuccess = onAuthRefreshSuccess;
  state.keycloak.onAuthRefreshError = onAuthRefreshError;
  state.keycloak.onAuthLogout = onAuthLogout;
  state.keycloak.onTokenExpired = onTokenExpired;

  state.keycloak
    .init(KEYCLOAK_INIT_OPTIONS)
    .success(keycloakInitSuccess)
    .error(keycloakInitError);
}

/**
 * Keycloak init success handler.
 * @memberof module:session
 * @param {Boolean} authenticated whether the user is authenticated or not
 * @private
 */
function keycloakInitSuccess(authenticated) {
  log("[session.js] initialized (authenticated: " + authenticated + ")");

  if (authenticated) {
    resetFailCount();
    setToken(state.keycloak.token);
    setRefreshToken(state.keycloak.refreshToken);
    startRefreshLoop();
  }

  keycloakInitHandler();
}

/**
 * Call any init event handlers that have are registered.
 *
 * @memberof module:session
 * @private
 */
function handleEvents(type) {
  var eventHandlers = events[type.toLowerCase()];
  if (!eventHandlers) {
    return;
  }
  while (eventHandlers.length) {
    var event = eventHandlers.shift();
    if (typeof event === "function") {
      log("[session.js] running a " + type + " handler");
      event(API);
    }
  }
}

/**
 * Register a function to be called when a session.js event has occurred.
 * When called, the function will be passed a reference to the session.js
 * API.
 *
 * Valid event types are:
 *
 *  - init
 *  - AuthSuccess
 *  - AuthError
 *  - AuthRefreshSuccess
 *  - AuthRefreshError
 *  - AuthLogout
 *
 * @memberof module:session
 * @param {String} eventType the name of the event (case insensitive).
 * @param {Function} func A handler function to call when session.js
 * finishes initializing.
 */
function on(eventType, func) {
  log("[session.js] registering a " + eventType + " handler");
  var eventHandlers = events[eventType.toLowerCase()];

  // handle 'init' event specially because it's a sessionjs event, not a
  // keycloak.js event.  run the handler immediately if session.js is
  // already initialized.
  if (eventType.toLowerCase() === "init" && state.initialized) {
    func(API);
  } else {
    if (!eventHandlers) {
      events[eventType.toLowerCase()] = [];
    }
    events[eventType.toLowerCase()].push(func);
  }
}

/**
 * Register a function to be called when session.js has initialized.  Runs
 * immediately if already initialized.  When called, the function will be
 * passed a reference to the session.js API.
 *
 * This function exists for backwards compatibility.  It uses `on('init', func)` internally.
 *
 * @memberof module:session
 * @param {Function} func A handler function to call when session.js
 * finishes initializing.
 */
function onInit(func) {
  on("init", func);
}

/**
 * Keycloak init error handler.
 * @memberof module:session
 * @private
 */
function keycloakInitError() {
  log("[session.js] init error");

  keycloakInitHandler();

  removeToken();
  removeRefreshToken();
}

/**
 * Does some things after keycloak initializes, whether or not
 * initialization was successful.
 *
 * @memberof module:session
 * @private
 */
function keycloakInitHandler() {
  state.initialized = true;
  handleEvents("init");
}

/**
 * Creates a URL to the SSO service based on an old IDP URL.
 *
 * @memberof module:session
 * @returns {String} a URL to the SSO service
 * @private
 */
function ssoUrl() {
  var idp_url = window.portal && portal.idp_url;
  var sso_url;

  if (idp_url) {
    // sculpt an environment-specific url
    sso_url = idp_url.replace("idp", "sso").replace(/\/$/, "") + "/auth";
  } else {
    switch (location.hostname) {
      // Valid PROD URLs
      case "access.redhat.com":
      case "prod.foo.redhat.com":
      case "rhn.redhat.com":
      case "hardware.redhat.com":
      case "www.redhat.com":
      case "route-customer-portal-prod-prod.apps.ext-waf.spoke.prod.us-west-2.aws.paas.redhat.com":
      case "route-customer-portal-prod-prod.apps.ext-waf.spoke.prod.us-east-1.aws.paas.redhat.com":
      case "dxp-docs.ext.us-west.aws.prod.paas.redhat.com":
      case "dxp-dxsp-prod.apps.ext-waf.spoke.prod.us-west-2.aws.paas.redhat.com":
      case "dxp-dxsp-prod.apps.ext-waf.drop.prod.us-west-2.aws.paas.redhat.com":
      case "dxp-dcp-prod.apps.ext-waf.spoke.prod.us-west-2.aws.paas.redhat.com":
      case "dxp-dcp-prod.apps.ext-waf.drop.prod.us-west-2.aws.paas.redhat.com":
      case "dcp002-prod.apps.ext-waf.spoke.prod.us-west-2.aws.paas.redhat.com":
      case "dxp-cphb-prod.apps.ext-waf.drop.prod.us-west-2.aws.paas.redhat.com":
      case "dxp-cppg-prod.apps.ext-waf.drop.prod.us-west-2.aws.paas.redhat.com":
      case "dxp-kbase-prod.apps.ext-waf.spoke.prod.us-west-2.aws.paas.redhat.com":
        log("[session.js] ENV: prod");
        return "https://sso.redhat.com/auth";

      // Valid STAGE URLs
      case "access.stage.redhat.com":
      case "accessstage.usersys.redhat.com":
      case "stage.foo.redhat.com":
      case "www.stage.redhat.com":
      case "hwcert-web-01.host.stage.cwe.eng.phx2.redhat.com":
      case "hwcert-web-01.host.stage.cwe.eng.phx.redhat.com":
      case "dxp-docs-stage.ext.us-east.aws.preprod.paas.redhat.com":
      case "dxp-dxsp-stage.apps.ext-waf.spoke.preprod.us-east-1.aws.paas.redhat.com":
      case "dxp-dxsp-stage.apps.ext-waf.drop.preprod.us-east-1.aws.paas.redhat.com":
      case "dxp-dcp-stage.apps.ext-waf.spoke.preprod.us-east-1.aws.paas.redhat.com":
      case "dxp-dcp-stage.apps.ext-waf.drop.preprod.us-east-1.aws.paas.redhat.com":
      case "dcp002-stage.apps.ext-waf.spoke.preprod.us-east-1.aws.paas.redhat.com":
      case "dxp-cphb-stage.apps.ext-waf.drop.preprod.us-east-1.aws.paas.redhat.com":
      case "dxp-cppg-stage.apps.ext-waf.drop.preprod.us-east-1.aws.paas.redhat.com":
      case "dxp-kbase-stage.apps.ext-waf.spoke.preprod.us-east-1.aws.paas.redhat.com":
        log("[session.js] ENV: stage");
        return "https://sso.stage.redhat.com/auth";

      // Valid QA URLs
      case "access.qa.redhat.com":
      case "qa.foo.redhat.com":
      case "accessqa.usersys.redhat.com":
      case "www.qa.redhat.com":
      case "dxp-docs-qa.ext.us-east.aws.preprod.paas.redhat.com":
      case "dxp-dxsp-qa.apps.ext-waf.spoke.preprod.us-east-1.aws.paas.redhat.com":
      case "dxp-dxsp-qa.apps.ext-waf.drop.preprod.us-east-1.aws.paas.redhat.com":
      case "dxp-dcp-qa.apps.ext-waf.spoke.preprod.us-east-1.aws.paas.redhat.com":
      case "dxp-dcp-qa.apps.ext-waf.drop.preprod.us-east-1.aws.paas.redhat.com":
      case "dcp002-qa.apps.ext-waf.spoke.preprod.us-east-1.aws.paas.redhat.com":
      case "dxp-cphb-qa.apps.ext-waf.drop.preprod.us-east-1.aws.paas.redhat.com":
      case "dxp-cppg-qa.apps.ext-waf.drop.preprod.us-east-1.aws.paas.redhat.com":
      case "dxp-kbase-qa.apps.ext-waf.spoke.preprod.us-east-1.aws.paas.redhat.com":
        log("[session.js] ENV: qa");
        return "https://sso.qa.redhat.com/auth";

      // Valid CI URLs
      default:
        log("[session.js] ENV: dev/ci");
        return "https://sso.qa.redhat.com/auth";
    }
  }

  return sso_url;
}

/**
 * A handler for when authentication is successfully established.
 *
 * @memberof module:session
 * @private
 */
function onAuthSuccess() {
  handleEvents("AuthSuccess");
}

function onAuthError() {
  removeToken();
  removeRefreshToken();
  handleEvents("AuthError");
}

function onAuthRefreshSuccess() {
  handleEvents("AuthRefreshSuccess");
}
function onAuthRefreshError() {
  handleEvents("AuthRefreshError");
}
function onAuthLogout() {
  logout({ skipRedirect: true });
  handleEvents("AuthLogout");
}
function onTokenExpired() {
  handleEvents("TokenExpired");
}

/**
 * Refreshes the access token.
 *
 * @memberof module:session
 */
function updateToken(force) {
  var shouldUpdate = force || !failCountPassed();

  // if forced update, use large number to force a token refresh, otherwise use regular refresh tte
  var refreshTTE = force ? Infinity : REFRESH_TTE;

  if (shouldUpdate) {
    log("[session.js] running updateToken");
    return state.keycloak
      .updateToken(refreshTTE)
      .success(updateTokenSuccess)
      .error(updateTokenFailure);
  } else {
    log(
      "[session.js] not updating token because updating failed more than " +
        FAIL_COUNT_THRESHOLD +
        " times in a row"
    );
    reportProblem(
      "gave up on token updates due after " +
        FAIL_COUNT_THRESHOLD +
        " consecutive failed token updates"
    );
  }
}

/**
 * Start the {@link module:session.refreshLoop refreshLoop}, which
 * periodically updates the authentication token.
 *
 * @memberof module:session
 * @private
 */
function startRefreshLoop() {
  // now start the token refresh interval
  setInterval(refreshLoop, REFRESH_INTERVAL);
}

/**
 * This is run periodically by {@link module:session.startRefreshLoop
 * startRefreshLoop}.
 *
 * @memberof module:session
 * @private
 */
function refreshLoop() {
  updateToken();
}

/**
 * Handler run when a token is successfully updated.
 *
 * @memberof module:session
 * @private
 */
function updateTokenSuccess(refreshed) {
  if (refreshed) {
    log("[session.js] updateTokenSuccess: token was refreshed");
    setToken(state.keycloak.token);
    setRefreshToken(state.keycloak.refreshToken);
    resetFailCount(); // token update worked, so reset number of consecutive failures
    cacheTimeSkew(state.keycloak.timeSkew || 0);
    setRavenUserContext();
  } else {
    log("[session.js] updateTokenSuccess: token not yet due for refresh");
  }
}

/**
 * Cache the time skew in localStorage so we can pass it back into keycloak
 * on the next pageview.  This prevents the JWT from refreshing on every
 * single pageview, since Keycloak.js can know how exactly when the
 * existing token will expire *on the server*.  That can only be done
 * when Keycloak.js knows the difference between client time and server
 * time.
 * @memberof module:session
 * @private
 */
function cacheTimeSkew(timeSkew) {
  if (Math.abs(timeSkew) < MAX_CACHED_TIMESKEW) {
    log("[session.js] caching the timeSkew (" + timeSkew + ")");
    store.set(TIMESKEW_CACHE_NAME, timeSkew);
  } else {
    log(
      "[session.js] not caching the timeSkew because magnitude too high (" +
        timeSkew +
        ")"
    );
  }
}

/**
 * Retrieve the cached timeskew (if any) from localStorage.
 * @memberof module:session
 * @private
 */
function getCachedTimeSkew() {
  return store.get(TIMESKEW_CACHE_NAME);
}

/**
 * Check for any extremely high cached timeskews and clear them.
 * @memberof module:session
 * @private
 */
function clearExtremeTimeSkewCache() {
  if (Math.abs(getCachedTimeSkew()) > MAX_CACHED_TIMESKEW) {
    log(
      "[session.js] deleting cached timeskew, it was " +
        getCachedTimeSkew() +
        "s which exceeds the limit of " +
        MAX_CACHED_TIMESKEW +
        "s"
    );
    store.remove(TIMESKEW_CACHE_NAME);
  }
}

/**
 * Handler run when a token update fails.
 *
 * @memberof module:session
 * @private
 */
function updateTokenFailure(load_failure) {
  log("[session.js] updateTokenFailure");
  incFailCount();
}

/**
 * Save the refresh token value in a semi-persistent place (sessionStorage).
 *
 * @memberof module:session
 * @private
 */
function setRefreshToken(refresh_token) {
  log("[session.js] setting refresh token");
  store.set(REFRESH_TOKEN_NAME, refresh_token);
}

/**
 * Remove the token value from its a semi-persistent place.
 *
 * @memberof module:session
 * @private
 */
function removeRefreshToken() {
  log("[session.js] removing refresh token");
  store.remove(REFRESH_TOKEN_NAME);
}

/**
 * Save the token value in a semi-persistent place (cookie).
 *
 * @memberof module:session
 * @private
 */
function setToken(token) {
  // make sure token is defined
  if (token) {
    // save the token in localStorage AND in a cookie.  the cookie
    // exists so it'll be sent along with AJAX requests.  the
    // localStorage value exists so the token can be refreshed even if
    // it's been expired for a long time.
    // extrapolate token's duration by finding the difference between the expiry time (exp) and the issued time (iat)
    var expiresIn = Math.floor(
      state.keycloak.tokenParsed.exp -
        new Date().getTime() / 1000 +
        getCachedTimeSkew()
    );
    log(
      "[session.js] setting access token with lifetime " +
        expiresIn +
        " seconds"
    );
    store.set(TOKEN_NAME, token);
    document.cookie =
      COOKIE_NAME +
      "=" +
      token +
      ";path=/;max-age=" +
      expiresIn +
      ";domain=." +
      origin +
      "; SameSite=None; secure;";
  }
}

/**
 * Remove the token value from its a semi-persistent place.
 *
 * @memberof module:session
 * @private
 */
function removeToken() {
  log("[session.js] removing access token");
  store.remove(TOKEN_NAME);
  document.cookie =
    COOKIE_NAME +
    "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=." +
    origin +
    "; path=/;SameSite=None;secure;";
  // Just to be sure that insecure cookies, and cookies without SameSite, created by past versions of this module, are deleted.
  document.cookie =
    COOKIE_NAME +
    "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=." +
    origin +
    "; path=/;secure;";
  document.cookie =
    COOKIE_NAME +
    "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=." +
    origin +
    "; path=/;";
}

/**
 * Get an object containing the parsed JSON Web Token.  Contains user and session metadata.
 *
 * @memberof module:session
 * @return {Object} the parsed JSON Web Token
 */
function getToken() {
  return state?.keycloak?.tokenParsed;
}

/**
 * Get a string containing the unparsed, base64-encoded JSON Web Token.
 *
 * @memberof module:session
 * @return {Object} the parsed JSON Web Token
 */
function getEncodedToken() {
  return state.keycloak.token;
}

/**
 * Return true if the token is expired.  If you provide a numerical
 * argument (minValidity), it will return true if the token _would_ expire
 * within minValidity seconds from now.
 *
 * This function is currently a pass-through to Keycloak.js's isTokenExpired function.
 *
 * @memberof module:session
 * @return {Boolean} true if the token would
 * @see https://www.keycloak.org/docs/latest/securing_apps/index.html#istokenexpired-minvalidity
 */
function isTokenExpired(minValidity) {
  return state.keycloak.isTokenExpired(minValidity);
}

/**
 * Get the user info from the JSON Web Token.  Contains user information
 * similar to what the old userStatus REST service returned.
 *
 * @memberof module:session
 * @return {Object} the user information
 */
function getUserInfo() {
  // the properties to return
  var token = getToken();
  var user = {};
  if (token) {
    user = {
      user_id: token.user_id,
      username: token.username,
      account_id: token.account_id,
      account_number: token.account_number,
      email: token.email,
      firstName: token.firstName,
      lastName: token.lastName,
      lang: token.lang,
      region: token.region,
      login: token.username,
      internal: isInternal(),
    };
  }
  return user;
}

/**
 * Is the user authenticated?
 *
 * @memberof module:session
 * @returns {Boolean} true if the user is authenticated, false otherwise
 */
function isAuthenticated() {
  return state.keycloak.authenticated;
}

/**
 * Is the user is a Red Hat employee?
 *
 * @memberof module:session
 * @returns {Boolean} true if the user is a Red Hat employee, otherwise false
 */
function isInternal() {
  return state.keycloak.hasRealmRole(INTERNAL_ROLE);
}

/**
 * Returns true if the user has all the given role(s).  You may provide any
 * number of roles.
 *
 * @param {...String} roles All the roles you wish to test for.  See
 * examples.
 * @returns {Boolean} whether the user is a member of ALL given roles
 * @example session.hasRole('portal_manage_cases');
 * session.hasRole('role1', 'role2', 'role3');
 * @memberof module:session
 */
function hasRole() {
  for (var i = 0; i < arguments.length; ++i) {
    if (!state.keycloak.hasRealmRole(arguments[i])) {
      return false;
    }
  }
  return true;
}

/**
 * Get the URL to the registration page.
 * @return {String} the URL to the registration page
 * @memberof module:session
 */
function getRegisterUrl() {
  return state.keycloak.createRegisterUrl();
}

/**
 * Get the URL to the login page.
 * @return {String} the URL to the login page
 * @memberof module:session
 */
function getLoginUrl(optionsIn) {
  var options = optionsIn || {};

  var redirectUri = options.redirectUri || location.href;

  options.redirectUri = getBounceUrl(redirectUri);
  options.scope = options.scope || SCOPES;

  log("[session.js] getLoginUrl's bounceUri is " + options.redirectUri);

  return state.keycloak.createLoginUrl(options);
}

/**
 * Provide the URL you want to be redirected to after the login process is
 * complete, and this function will generate a "bounce URL", which
 * includes the 'scribe' page in the path.  The scribe page is
 * responsible for writing the rh_jwt cookie before the browser is
 * returned to your page, so that the app server (in cases where there is one), will have access to the cookie.
 *
 * @param {String} redirectTo the URL you want to be redirected to after the login process is complete.
 * @return {String} the bounce URL
 */
function getBounceUrl(redirectTo) {
  var redirectUri = redirectTo || location.href;
  var uri = new URL(redirectUri);
  var bounceUri =
    uri.origin + getBouncePath() + encodeURIComponent(redirectUri);
  return bounceUri;
}

/**
 * Get the URL to the logout page.
 * @return {String} the URL to the logout page
 * @memberof module:session
 */
function getLogoutUrl() {
  let logoutUrl = state.keycloak.createLogoutUrl();
  if (logoutUrl && logoutUrl?.length) {
    logoutUrl = logoutUrl.includes('?')
      ? `${logoutUrl}&client_id=${CLIENT_ID}`
      : `${logoutUrl}?client_id=${CLIENT_ID}`;
  }
  return logoutUrl;
}

/**
 * Get the URL to the account management page.
 * @return {String} the URL to the account management page
 * @memberof module:session
 */
function getAccountUrl() {
  return state.keycloak.createAccountUrl();
}

/**
 * "Decorator" enforcing that session.js be initialized before the wrapped
 * function will be run.
 *
 * @memberof module:session
 * @private
 * @param {Function} func a function which shouldn't be run before session.js is
 * initialized.
 * @return {Function}
 */
function initialized(func) {
  return function () {
    if (state.initialized) {
      return func.apply({}, arguments);
    } else {
      console.warn(
        "[session.js] couldn't call function, session not initialized"
      );
      return;
    }
  };
}

/**
 * Logs the user in.  An unauthenticated user will be sent to the
 * credentials form and then back to the current page.  An authenticated
 * user will be sent to the Keycloak server but bounced back to the current
 * page right away.
 *
 * @memberof module:session
 * @param {Object} options See [options](https://keycloak.gitbooks.io/securing-client-applications-guide/content/v/2.2/topics/oidc/javascript-adapter.html#_login_options) for valid options.
 */
function login(optionsIn) {
  let options = optionsIn || {};
  let redirectUri = options.redirectUri || location.href;
  options.redirectUri = getBounceUrl(redirectUri);
  options.scope = options.scope || SCOPES;

  log("[session.js] login() is redirecting to: " + options.redirectUri);

  state.keycloak.login(options);
}

/**
 * Navigate to the logout page, end session, then navigate back.
 * @memberof module:session
 */
function logout(options) {
  let opts = options || {};

  resetFailCount();
  removeToken();
  removeRefreshToken();

  if (!opts.skipRedirect) {
    let logoutPageUrl = window.location.origin + "/logout";
    let logoutBeginUrl = state.keycloak.createLogoutUrl({
      redirectUri: opts.redirectUri || logoutPageUrl,
    });

    if (logoutBeginUrl && logoutBeginUrl?.length) {
      logoutBeginUrl = logoutBeginUrl.includes('?')
        ? `${logoutBeginUrl}&client_id=${CLIENT_ID}`
        : `${logoutBeginUrl}?client_id=${CLIENT_ID}`;
    }

    window.location.href = logoutBeginUrl;
  }
}

/**
 * Navigate to the account registration page.
 *
 * @memberof module:session
 */
function register(options) {
  state.keycloak.register(options);
}

/**
 * Returns the path to the "scribe" page (login bounce page that writes the
 * rh_jwt cookie and localStorage refresh token.
 */
function getBouncePath() {
  return SCRIBE_PATH;
}

/**
 * Send current user context to Raven (JS error logging library).
 * @memberof module:session
 * @private
 */
function setRavenUserContext() {
  // once the user info service has returned, use its data to add user
  // context to RavenJS, for inclusion in Sentry error reports.
  var data = getUserInfo();
  if (
    typeof window.Raven !== "undefined" &&
    typeof window.Raven.setUserContext === "function"
  ) {
    var rh_jwt_cookie = cookie.getCookieValue(COOKIE_NAME);
    var rh_jwt_ls = store.get(COOKIE_NAME);

    // You can't send PII to Sentry.  Because PII can't be sent to
    // Sentry.  Sentry is not a tool that can be used with PII.  As
    // I have answered Sentry-related questions so many times before,
    // the use of Sentry will not allow you to use PII.  Sentry is
    // a tool that is forbidden from receiving PII.  The Sentry
    // contract is not equipped to persist our PII. so many times but
    // it is not getting to me.  Even paying for Sentry hosting as used
    // by us are not up to the task of persist PII. You will never make
    // me crack. Sentry is a tool of sufficient externality that it
    // cannot persist our PII. Even on-prem Sentry cannot persist PII.
    // Every time you attempt to persist PII with Sentry, the unholy
    // child weeps the blood of virgins, and Russian hackers pwn your
    // webapp. Persisting PII with Sentry summons tainted souls into
    // the realm of the living. PII and Sentry go together like love,
    // marriage, and ritual infanticide. The <center> cannot hold it is
    // too late. The force of Sentry and PII together in the same
    // conceptual space will destroy your mind like so much watery
    // putty. If you persist PII with Sentry you are giving in to Them
    // and their blasphemous ways which doom us all to inhuman toil for
    // the One whose Name cannot be expressed in the Basic Multilingual
    // Plane, he comes.  PII-plus-Sentry will liquify the nerves of the
    // sentient whilst you observe, your psyche withering in the
    // onslaught of horror. S̶e̛nt̢ry PII persist are the cancer that is
    // killing The Web it is too late it is too late we cannot be saved
    // the trangession of a chi͡ld ensures Sentry will consume all
    // living tissue (except for PII which it cannot, as previously
    // prophesied) dear lord help us how can anyone survive this
    // scourge using Sentry to persist PII has doomed humanity to an
    // eternity of dread torture and security holes using Sentry as
    // a tool to persist PII establishes a breach between this world
    // and the dread realm of c͒ͪo͛ͫrrupt entities a mere glimpse of the
    // world of S͞e͟n̴t̴ry persistence for PII will instantly transport
    // a programmer's consciousness into a world of ceaseless
    // screaming, he comes, the pestilent slithy Sentry-infection will
    // devour your HTML parser, application and existence for all time
    // like CoffeeScript only worse he comes he comes do not fight he
    // com̡e̶s, ̕h̵is un̨ho͞ly radiańcé destro҉ying all enli̍̈́̂̈́ghtenment, PII
    // data lea͠ki̧n͘g fr̶ǫm ̡your eye͢s̸ ̛l̕ik͏e liquid pain, the song of P̶̕I̷I͝
    // persistence will extinguish the voices of mortal man from the
    // sphere I can see it can you see ̲͚̖͔̙î̩́t̲͎̩̱͔́̋̀ it is beautiful the final
    // snuffing of the lies of Man ALL IS LOŚ͖̩͇̗̪̏̈́T ALL IS LOST the pon̷y he
    // comes he c̶̮omes he comes the ichor permeates all MY FACE MY FACE
    // ᵒh god no NO NOO̼OO NΘ stop the an*̶͑̾̾g͇̫͛͆̾ͫ̑͆l͖͉̗̩̳̟̍ͫͥͨe̠̅s ͎a̧͈͖r̽̾̈́͒͑e not rè̑ͧ̌aͨl̘̝̙̃ͤ͂̾̆ ZA̡͊͠͝LGΌ ISͮ̂҉̯͈͕̹̘̱
    // TO͇̹̺ͅƝ̴ȳ̳ TH̘Ë͖́̉ ͠P̯͍̭O̚N̐Y̡ H̸̡̪̯ͨ͊̽̅̾̎Ȩ̬̩̾͛ͪ̈́̀́͘ ̶̧̨̱̹̭̯ͧ̾ͬC̷̙̲̝͖ͭ̏ͥͮ͟Oͮ͏̮̪̝͍M̲̖͊̒ͪͩͬ̚̚͜Ȇ̴̟̟͙̞ͩ͌͝S̨̥̫͎̭ͯ̿̔̀ͅ

    Raven.setUserContext({
      id: data.user_id,
      chrome_session_id: cookie.getCookieValue("chrome_session_id"),
      sessionjs: {
        rh_sso_session: !!cookie.getCookieValue("rh_sso_session"),
        jwt: {
          rh_user_cookie_exists: !!cookie.getCookieValue("rh_user"), // does this cookie exist, value is irrelevant
          rh_jwt_cookie_exists: !!rh_jwt_cookie, // does this cookie exist, value is irrelevant
          rh_jwt_localstorage_exists: !!rh_jwt_ls, // does this exist, value is irrelevant
          rh_jwt_cookie_matches_localstorage: rh_jwt_cookie === rh_jwt_ls,
          refresh_token_localstorage_exists: !!store.get(REFRESH_TOKEN_NAME), // does this exist, value is irrelevant
          user_id: data.user_id,
          account_id: data.account_id,
          account_number: data.account_number,
          internal: data.internal,
          lang: data.lang,
        },
      },
    });

    log("[session.js] sent user context to Raven");
  }
}

/**
 * Receive an error report from an application and send it to a remote logging service.  Follows the same function signature as [Raven.captureMessage](https://docs.sentry.io/clients/javascript/usage/#capturing-messages).
 * @memberof module:session
 * @param {String|Error} msg The message or error object to log
 * @param {Object} options options object to customize the report, see [Raven.captureMessage](https://docs.sentry.io/clients/javascript/usage/#capturing-messages)
 */
function reportProblem(msg, options) {
  // update user context before sending, in case any user metadata or
  // cookies have changed since it was first set
  setRavenUserContext();

  // add some extra metadata to the message.  more fields can be provided with
  // the `options` object, but `tags.chrome_module` and `level` are fixed.
  var opts = {
    ...options,
    ...{
      tags: {
        ...(options.tags || {}),
        chrome_module: "session",
      },
      level: "error",
    },
  };

  if (
    typeof window.Raven !== "undefined" &&
    typeof window.Raven.captureMessage === "function"
  ) {
    log("[session.js] sending a problem report to Sentry");
    Raven.captureException(msg, opts || {});
  }
}

/**
 * Get the number of times in a row that token refresh has failed.
 * @return {Number} the number of times that token refresh has failed
 * @memberof module:session
 */
function getFailCount() {
  return store.get(FAIL_COUNT_NAME) || 0;
}

/**
 * Return whether or not the consecutive failure count has been exceeded.
 * @memberof module:session
 * @return {Boolean} has the consecutive failure count been exceeded
 */
function failCountPassed() {
  return getFailCount() > FAIL_COUNT_THRESHOLD;
}

/**
 * Increment the number of times in a row that token refresh has failed.
 * @return {Number} the new, incremented number of times that token refresh has failed
 */
function incFailCount() {
  // only increment the fail count if the token is *not* expired.  this
  // prevents tabs with expired tokens from inflating the failure count

  // isTokenExpired errors if the user is unauthenticated, so in essence
  // this is checking whether the user has a valid session (with
  // associated valid refresh token), and if so, then checks if the
  // access token is expired.  if both the refresh token and access token
  // are valid, then increment the fail count.
  try {
    if (!state.keycloak.isTokenExpired()) {
      var currentCount = getFailCount();
      currentCount += 1;
      store.set(FAIL_COUNT_NAME, currentCount);
      return currentCount;
    }
  } catch (e) {}
}

/**
 * Reset the number of times in a row that token refresh has failed.
 * @memberof module:session
 * @private
 */
function resetFailCount() {
  store.set(FAIL_COUNT_NAME, 0);
}

/**
 * This function handles cases where session.js changes its data structre
 * in some way that would affect users who have existing an session,
 * cookies, or localStorage values.  It will change and expand as migration
 * needs arise.
 *
 * To add a new migration step, add a named function inside migrate, then
 * call it at the top.  Migration steps will be run every time session.js
 * initializes, so be sure migration steps have no side effects when run
 * multiple times.  Look within for examples.
 *
 * @memberof module:session
 * @private
 */
function migrate() {
  // run migration steps

  migrateToNamespacedLocalStorage();

  // migration steps defined below

  function migrateToNamespacedLocalStorage() {
    // added for CPFED-2240
    // check for the old localStorage keys and if they exist, move them to the new, namespaced keys
    var oldToken = store.get("rh_jwt");
    var oldRefreshToken = store.get("rh_refresh_token");
    var oldTimeSkew = store.get("rh_jwt_timeskew");

    if (oldToken) {
      store.set(TOKEN_NAME, oldToken);
      store.remove("rh_jwt");
    }

    if (oldRefreshToken) {
      store.set(REFRESH_TOKEN_NAME, oldRefreshToken);
      store.remove("rh_refresh_token");
    }

    if (oldTimeSkew) {
      store.set(TIMESKEW_CACHE_NAME, oldTimeSkew);
      store.remove("rh_jwt_timeskew");
    }
  }
}

var API = {
  login: initialized(login),
  logout: initialized(logout),
  register: initialized(register),
  hasRole: initialized(hasRole),
  isInternal: initialized(isInternal),
  isAuthenticated: initialized(isAuthenticated),
  getRegisterUrl: initialized(getRegisterUrl),
  getLoginUrl: initialized(getLoginUrl),
  getLogoutUrl: initialized(getLogoutUrl),
  getAccountUrl: initialized(getAccountUrl),
  getToken: initialized(getToken),
  getEncodedToken: initialized(getEncodedToken),
  isTokenExpired: initialized(isTokenExpired),
  getUserInfo: initialized(getUserInfo),
  updateToken: initialized(updateToken),
  on: on,
  onInit: onInit,
  init: init,
  getBouncePath: getBouncePath,
  _state: state,
  getFailCount: getFailCount,
  failCountPassed: failCountPassed,
  reportProblem: reportProblem,
};

export default API;
