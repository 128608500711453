let DEFAULT = "en";
const data_attr = "data-lang";
let locale_picker_options = Array.from(
  document.querySelectorAll("#localesMenu a[" + data_attr + "]")
);
const names = {
  en: "English",
  fr: "Français",
  ko: "한국어",
  ja: "日本語",
  zh_CN: "中文 (中国)",
};

/** Returns closest support locale
 * @function closest_locale()
 * @param locale 
 * @returns string
 */
function closest_locale(locale) {
  let closest = DEFAULT;
  let supported = supported_locales();
  let is_supported = supported.indexOf(locale) >= 0;
  let left_part = locale.split("_")[0];
  let left_supported = supported.indexOf(left_part) >= 0;

  if (is_supported) {
    closest = locale;
  } else if (left_supported) {
    closest = left_part;
  } else {
    closest = "en"
  }

  return closest;
}

/** Returns supported locales
 * @function supported_locales()
 * @returns {string[]} An array of strings containing the language codes we support
 */
function supported_locales() {
  const pickers = locale_picker_options.map(data_lang);
  return pickers;
}

function data_lang(el, i) {
  return el.getAttribute(data_attr);
}

/**
 * Gets language name from laguage code
 * Returns Language name in native language
 * @function name()
 * @param lang
 * @returns string
 **/
function name(lang: string) {
  return names[lang];
}

const lang_api = {
  default: DEFAULT, // "en"
  closest: closest_locale,
  supported: supported_locales, // "en", "fr", "ko", "ja", "zh"
  name: name,
};

export default lang_api;
